var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      staticStyle: {
        background: "#fff",
        height: "auto",
        overflow: "overlay",
        "flex-direction": "column",
      },
    },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: {
            padding: "20px",
            "flex-grow": "1",
            overflow: "hidden overlay",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "status-icon": "",
                model: _vm.dkbPdProd,
                "label-width": "120px",
              },
            },
            [
              _c("el-col", { staticClass: "title-bar", attrs: { span: 24 } }, [
                _vm._v("组合包装药品信息"),
              ]),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.dkbPdProd.id,
                              expression: "dkbPdProd.id",
                            },
                          ],
                          attrs: { label: "包装药品ID:" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px", float: "left" },
                            attrs: { disabled: true },
                            model: {
                              value: _vm.dkbPdProd.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.dkbPdProd, "id", $$v)
                              },
                              expression: "dkbPdProd.id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "包装药品名称:",
                            prop: "drugName",
                            rules: [
                              {
                                required: true,
                                message: "请输入包装药品名称",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入包装药品名称",
                              maxlength: "300",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.dkbPdProd.drugName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dkbPdProd, "drugName", $$v)
                              },
                              expression: "dkbPdProd.drugName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "包装规格描述:",
                            prop: "packSpecDesc",
                            rules: [
                              {
                                required: true,
                                message: "请输入包装规格描述",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              placeholder: "请输入包装规格描述",
                              maxlength: "150",
                              clearable: "",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.dkbPdProd.packSpecDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.dkbPdProd, "packSpecDesc", $$v)
                              },
                              expression: "dkbPdProd.packSpecDesc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "数量:",
                            prop: "packQty",
                            rules: [
                              {
                                required: true,
                                message: "请输入数量",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              min: 0,
                              max: 100000000,
                              controls: "",
                              "controls-position": "right",
                              precision: 2,
                            },
                            model: {
                              value: _vm.dkbPdProd.packQty,
                              callback: function ($$v) {
                                _vm.$set(_vm.dkbPdProd, "packQty", $$v)
                              },
                              expression: "dkbPdProd.packQty",
                            },
                          }),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                filterable: "",
                                "filter-method": _vm.packQtyUnitFilter,
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.packQtyUnitDictOptions =
                                    _vm.packQtyUnitDict
                                },
                              },
                              model: {
                                value: _vm.dkbPdProd.specUnit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dkbPdProd, "specUnit", $$v)
                                },
                                expression: "dkbPdProd.specUnit",
                              },
                            },
                            _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                              return _c("el-option", {
                                key: "specUnit" + item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                margin: "5px",
                                "font-size": "20px",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v("/")]
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100px" },
                              attrs: {
                                filterable: "",
                                "filter-method": _vm.packQtyUnitFilter,
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.packQtyUnitDictOptions =
                                    _vm.packQtyUnitDict
                                },
                              },
                              model: {
                                value: _vm.dkbPdProd.packQtyUnitId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dkbPdProd, "packQtyUnitId", $$v)
                                },
                                expression: "dkbPdProd.packQtyUnitId",
                              },
                            },
                            _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                              return _c("el-option", {
                                key: "packQtyUnitId" + item.conceptId,
                                attrs: {
                                  label: item.conceptVal,
                                  value: item.conceptId,
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "text" },
                              on: { click: _vm.editPdSpec },
                            },
                            [_vm._v("拆分")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "title-bar", attrs: { span: 24 } },
                    [_vm._v("药品列表")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-bottom": "10px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { type: "primary", icon: "fa fa-link" },
                          on: { click: _vm.queryDrugProd },
                        },
                        [_vm._v("关联药品 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { padding: "0px 0px 15px 15px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            data: _vm.dkbPdProd.drugProdList,
                            height: _vm.drugProdTableHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              property: "id",
                              label: "药品ID",
                              width: "80",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "药品名称" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [_vm._v(_vm._s(scope.row.drugProdName))]
                                    ),
                                    _vm._l(
                                      scope.row.drugCategoryList,
                                      function (item) {
                                        return _c(
                                          "span",
                                          { key: item.id },
                                          [
                                            item.icon
                                              ? _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": item.icon,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "approvalNum",
                              label: "批准文号",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "manufacturerName",
                              label: "生产企业",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { property: "specDesc", label: "规格描述" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", width: "150" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delDrugProd(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "title-bar", attrs: { span: 24 } },
                    [_vm._v("包装材料列表")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-bottom": "10px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.editPdProdMat()
                            },
                          },
                        },
                        [_vm._v("添加包装材料 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: {
                    padding: "0px 0px 15px 15px",
                    "margin-bottom": "50px",
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            data: _vm.dkbPdProd.pdProdMatList,
                            height: _vm.matTableHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              property: "id",
                              label: "包装材料ID",
                              width: "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "wrapperDesc",
                              label: "包装材料描述",
                              "min-width": "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "storage",
                              label: "储藏条件",
                              width: "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "有效期", width: "80" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.indate) +
                                        " " +
                                        _vm._s(scope.row.indateUnitDesc) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              property: "barcode69",
                              label: "编码",
                              "min-width": "200",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: scope.row.barcode69,
                                            expression: "scope.row.barcode69",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          "69条形码: " +
                                            _vm._s(scope.row.barcode69)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              scope.row.stateMedicareNumList.toString() !=
                                              "",
                                            expression:
                                              "scope.row.stateMedicareNumList.toString()!=''",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v("国家医保统编码: "),
                                        _vm._l(
                                          scope.row.stateMedicareNumList,
                                          function (item, index) {
                                            return _c(
                                              "span",
                                              {
                                                key: item + "stateMedicareNum",
                                              },
                                              [
                                                _vm._v(" " + _vm._s(item)),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          index <
                                                          scope.row
                                                            .stateMedicareNumList
                                                            .length -
                                                            1,
                                                        expression:
                                                          "index<scope.row.stateMedicareNumList.length-1",
                                                      },
                                                    ],
                                                  },
                                                  [_vm._v(";")]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "p",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              scope.row.shMedicareNumList.toString() !=
                                              "",
                                            expression:
                                              "scope.row.shMedicareNumList.toString()!=''",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v("上海医保统编码: "),
                                        _vm._l(
                                          scope.row.shMedicareNumList,
                                          function (item, index) {
                                            return _c(
                                              "span",
                                              { key: item + "shMedicareNum" },
                                              [
                                                _vm._v(" " + _vm._s(item)),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          index <
                                                          scope.row
                                                            .shMedicareNumList
                                                            .length -
                                                            1,
                                                        expression:
                                                          "index<scope.row.shMedicareNumList.length-1",
                                                      },
                                                    ],
                                                  },
                                                  [_vm._v(";")]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", width: "80" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editPdProdMat(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#FF3333" },
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delPdProdMat(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除 ")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("template", { slot: "empty" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/no-booking.svg"),
                                alt: "",
                              },
                            }),
                            _c("p", [_vm._v("暂无数据")]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-col",
            {
              staticClass: "bottom-bar",
              staticStyle: {
                height: "80px",
                "padding-right": "0px",
                position: "fixed",
                right: "30px",
                bottom: "10px",
              },
              attrs: { span: 20 },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dkbPdProd.id,
                      expression: "dkbPdProd.id",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editNext },
                },
                [_vm._v("下一个")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dkbPdProd.id,
                      expression: "dkbPdProd.id",
                    },
                  ],
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.editPrev },
                },
                [_vm._v("上一个")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-left": "10px" },
                  on: { click: _vm.handleReturn },
                },
                [_vm._v("返回 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(1)
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(2)
                    },
                  },
                },
                [_vm._v("保存并关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "药品检索",
            "append-to-body": "",
            width: "60%",
            visible: _vm.drugProd.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drugProd, "dialogVisible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "status-icon": "", model: _vm.drugProd } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "400px", float: "left" },
                    attrs: {
                      placeholder: "请输入关键字",
                      maxlength: "20",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.queryDrugProd($event)
                      },
                    },
                    model: {
                      value: _vm.drugProd.searchValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugProd, "searchValue", $$v)
                      },
                      expression: "drugProd.searchValue",
                    },
                  }),
                  _c("el-input", { staticStyle: { display: "none" } }),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        background: "#2B60F8",
                      },
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.queryDrugProd },
                    },
                    [_vm._v("检索 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.drugProdTableData,
                height: 300,
              },
            },
            [
              _c("el-table-column", {
                attrs: { property: "id", label: "药品ID", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { property: "drugProdName", label: "药品名称" },
              }),
              _c("el-table-column", {
                attrs: { property: "approvalNum", label: "批准文号" },
              }),
              _c("el-table-column", {
                attrs: { property: "manufacturerName", label: "生产企业" },
              }),
              _c("el-table-column", {
                attrs: { property: "specDesc", label: "规格描述" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.chooseDrugProd(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("选择 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.isDrugProd === false
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.drugProd.total > 0,
                expression: "drugProd.total>0",
              },
            ],
            attrs: {
              total: _vm.drugProd.total,
              page: _vm.drugProd.pageNum,
              limit: _vm.drugProd.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.drugProd, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.drugProd, "pageSize", $event)
              },
              pagination: _vm.queryDrugProd,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "编辑包装材料",
            width: "600px",
            "append-to-body": "",
            visible: _vm.editPdProdMatDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPdProdMatDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { padding: "20px" } },
            [
              _c(
                "el-form",
                {
                  ref: "editPdProdMatForm",
                  attrs: {
                    "status-icon": "",
                    model: _vm.editPdProdMatForm,
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "包装材料描述:",
                                prop: "wrapperDesc",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入包装材料描述",
                                    trigger: "blur",
                                  },
                                  {
                                    validator: _vm.validateWrapperDesc,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px", float: "left" },
                                attrs: {
                                  type: "textarea",
                                  rows: 6,
                                  placeholder: "请输入包装材料描述",
                                  maxlength: "1000",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.editPdProdMatForm.wrapperDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPdProdMatForm,
                                      "wrapperDesc",
                                      $$v
                                    )
                                  },
                                  expression: "editPdProdMatForm.wrapperDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "储藏条件:", prop: "storage" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  maxlength: "200",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.editPdProdMatForm.storage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPdProdMatForm,
                                      "storage",
                                      $$v
                                    )
                                  },
                                  expression: "editPdProdMatForm.storage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "有效期:",
                                prop: "indate",
                                rules: [],
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "120px", float: "left" },
                                attrs: {
                                  min: 0,
                                  max: 10000,
                                  controls: "",
                                  "controls-position": "right",
                                  precision: 0,
                                },
                                model: {
                                  value: _vm.editPdProdMatForm.indate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPdProdMatForm,
                                      "indate",
                                      $$v
                                    )
                                  },
                                  expression: "editPdProdMatForm.indate",
                                },
                              }),
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "100px",
                                    float: "left",
                                  },
                                  model: {
                                    value: _vm.editPdProdMatForm.indateUnit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editPdProdMatForm,
                                        "indateUnit",
                                        $$v
                                      )
                                    },
                                    expression: "editPdProdMatForm.indateUnit",
                                  },
                                },
                                _vm._l(_vm.timeUnitDict, function (item) {
                                  return _c("el-option", {
                                    key: "indateUnit" + item.conceptId,
                                    attrs: {
                                      label: item.conceptVal,
                                      value: item.conceptId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "69条形码:", prop: "barcode69" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  maxlength: "30",
                                  clearable: "",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.editPdProdMatForm.barcode69,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editPdProdMatForm,
                                      "barcode69",
                                      $$v
                                    )
                                  },
                                  expression: "editPdProdMatForm.barcode69",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        _vm._l(
                          _vm.editPdProdMatForm.stateMedicareNumList,
                          function (item, index) {
                            return _c(
                              "el-form-item",
                              {
                                key: "stateMedicareNum-" + index,
                                attrs: {
                                  label: index == 0 ? "国家医保统编码:" : "",
                                  rules: [
                                    {
                                      validator: _vm.validateMedicareNum,
                                      trigger: "blur",
                                    },
                                  ],
                                  prop: "stateMedicareNumList." + index,
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "320px",
                                    float: "left",
                                  },
                                  attrs: {
                                    placeholder: "请输入国家医保统编码",
                                    maxlength: "30",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.medicareNumInputFocus(
                                        "国家",
                                        index
                                      )
                                    },
                                  },
                                  nativeOn: {
                                    "!paste": function ($event) {
                                      $event.preventDefault()
                                      item == ""
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.editPdProdMatForm
                                        .stateMedicareNumList[index],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editPdProdMatForm
                                          .stateMedicareNumList,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editPdProdMatForm.stateMedicareNumList[index]",
                                  },
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.editPdProdMatForm
                                          .stateMedicareNumList.length > 1,
                                      expression:
                                        "editPdProdMatForm.stateMedicareNumList.length>1",
                                    },
                                  ],
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.delMedicareNum(
                                        item,
                                        index,
                                        "国家"
                                      )
                                    },
                                  },
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        index ==
                                          _vm.editPdProdMatForm
                                            .stateMedicareNumList.length -
                                            1 &&
                                        _vm.editPdProdMatForm
                                          .stateMedicareNumList.length < 5,
                                      expression:
                                        "index==editPdProdMatForm.stateMedicareNumList.length-1 && editPdProdMatForm.stateMedicareNumList.length<5",
                                    },
                                  ],
                                  staticClass: "el-icon-circle-plus",
                                  staticStyle: {
                                    "font-size": "20px",
                                    color: "#2B60F8",
                                    "margin-left": "4px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addMedicareNum("国家")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        _vm._l(
                          _vm.editPdProdMatForm.shMedicareNumList,
                          function (item, index) {
                            return _c(
                              "el-form-item",
                              {
                                key: "shMedicareNum-" + index,
                                attrs: {
                                  label: index == 0 ? "上海医保统编码:" : "",
                                  rules: [
                                    {
                                      validator: _vm.validateMedicareNum,
                                      trigger: "blur",
                                    },
                                  ],
                                  prop: "shMedicareNumList." + index,
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "320px",
                                    float: "left",
                                  },
                                  attrs: {
                                    placeholder: "请输入上海医保统编码",
                                    maxlength: "30",
                                    clearable: "",
                                    "show-word-limit": "",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.medicareNumInputFocus(
                                        "上海",
                                        index
                                      )
                                    },
                                  },
                                  nativeOn: {
                                    "!paste": function ($event) {
                                      $event.preventDefault()
                                      item == ""
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.editPdProdMatForm.shMedicareNumList[
                                        index
                                      ],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.editPdProdMatForm.shMedicareNumList,
                                        index,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "editPdProdMatForm.shMedicareNumList[index]",
                                  },
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.editPdProdMatForm.shMedicareNumList
                                          .length > 1,
                                      expression:
                                        "editPdProdMatForm.shMedicareNumList.length>1",
                                    },
                                  ],
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.delMedicareNum(
                                        item,
                                        index,
                                        "上海"
                                      )
                                    },
                                  },
                                }),
                                _c("i", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        index ==
                                          _vm.editPdProdMatForm
                                            .shMedicareNumList.length -
                                            1 &&
                                        _vm.editPdProdMatForm.shMedicareNumList
                                          .length < 5,
                                      expression:
                                        "index==editPdProdMatForm.shMedicareNumList.length-1 && editPdProdMatForm.shMedicareNumList.length<5",
                                    },
                                  ],
                                  staticClass: "el-icon-circle-plus",
                                  staticStyle: {
                                    "font-size": "20px",
                                    color: "#2B60F8",
                                    "margin-left": "4px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.addMedicareNum("上海")
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                "margin-left": "20px",
                                background: "#2B60F8",
                                float: "right",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.confirmPdProdMat },
                            },
                            [_vm._v("确定 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "10px",
                              },
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  _vm.editPdProdMatDialogVisible = false
                                },
                              },
                            },
                            [_vm._v("取消 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "国家医保药品目录",
            width: "80%",
            "append-to-body": "",
            visible: _vm.nhsaDrugDictDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.nhsaDrugDictDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "drugDictQueryForm",
                  attrs: { "status-icon": "", model: _vm.dictDrugForm },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px", float: "left" },
                        attrs: {
                          placeholder: "请输入药品名称/批准文号/本位码",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.selectDictDrug("国家")
                          },
                        },
                        model: {
                          value: _vm.dictDrugForm.drugName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dictDrugForm, "drugName", $$v)
                          },
                          expression: "dictDrugForm.drugName",
                        },
                      }),
                      _c("el-input", { staticStyle: { display: "none" } }),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            background: "#2B60F8",
                          },
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.selectDictDrug("国家")
                            },
                          },
                        },
                        [_vm._v("检索 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.dictDrugTableData,
                    height: 300,
                    border: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    stripe: "",
                  },
                  on: { "sort-change": _vm.drugDictTableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "registeredProductName",
                      label: "药品名称",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "displayName",
                      label: "规格剂型",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.registeredMedicineModel) +
                                _vm._s(scope.row.registeredOutlook) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      property: "realitySpec",
                      label: "实际规格",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "materialName",
                      label: "包装",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.materialName) +
                                "(" +
                                _vm._s(scope.row.factor) +
                                "/" +
                                _vm._s(scope.row.minUnit) +
                                "/" +
                                _vm._s(scope.row.unit) +
                                ") "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "companyNamesc",
                      label: "厂家",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      property: "approvalCode",
                      label: "批准文号",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "goodsStandardCode",
                      label: "本位码",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "goodsCode",
                      label: "国家医保编码",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "80",
                      property: "comment",
                      label: "备注信息",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "120", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectDrugDictTableRow(
                                      _vm.nowEditInputIndex,
                                      scope.row,
                                      "国家"
                                    )
                                  },
                                },
                              },
                              [_vm._v("选择 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editDrugComment(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v("编辑备注 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dictDrugForm.total > 0,
                    expression: "dictDrugForm.total>0",
                  },
                ],
                attrs: {
                  total: _vm.dictDrugForm.total,
                  page: _vm.dictDrugForm.pageNum,
                  limit: _vm.dictDrugForm.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.dictDrugForm, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.dictDrugForm, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.selectDictDrug("国家")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "上海医保药品目录",
            width: "80%",
            "append-to-body": "",
            visible: _vm.smpaDrugDictDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.smpaDrugDictDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "drugDictQueryForm",
                  attrs: { "status-icon": "", model: _vm.dictDrugForm },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px", float: "left" },
                        attrs: {
                          placeholder: "请输入药品名称/批准文号/本位码",
                          clearable: "",
                          "show-word-limit": "",
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.selectDictDrug("上海")
                          },
                        },
                        model: {
                          value: _vm.dictDrugForm.drugName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dictDrugForm, "drugName", $$v)
                          },
                          expression: "dictDrugForm.drugName",
                        },
                      }),
                      _c("el-input", { staticStyle: { display: "none" } }),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "20px",
                            background: "#2B60F8",
                          },
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: {
                            click: function ($event) {
                              return _vm.selectDictDrug("上海")
                            },
                          },
                        },
                        [_vm._v("检索 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    data: _vm.dictDrugTableData,
                    height: 300,
                    border: "",
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    stripe: "",
                  },
                  on: { "sort-change": _vm.drugDictTableSortChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "yptym",
                      label: "药品名称",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "displayName",
                      label: "剂型规格包装",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.jx) +
                                "--" +
                                _vm._s(scope.row.ggbz) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "ggbzwzbs",
                      label: "包装",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "scqy",
                      label: "厂家",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "100",
                      property: "pzwh",
                      label: "批准文号",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "bwm",
                      label: "本位码",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "150",
                      property: "tbdm",
                      label: "上海医保编码",
                      sortable: "custom",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "min-width": "80",
                      property: "comment",
                      label: "备注信息",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "120", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectDrugDictTableRow(
                                      _vm.nowEditInputIndex,
                                      scope.row,
                                      "上海"
                                    )
                                  },
                                },
                              },
                              [_vm._v("选择 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editDrugComment(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("编辑备注 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.dictDrugForm.total > 0,
                    expression: "dictDrugForm.total>0",
                  },
                ],
                attrs: {
                  total: _vm.dictDrugForm.total,
                  page: _vm.dictDrugForm.pageNum,
                  limit: _vm.dictDrugForm.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.dictDrugForm, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.dictDrugForm, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.selectDictDrug("上海")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "包装药品明细拆分",
            width: "60%",
            "append-to-body": "",
            visible: _vm.editPdSpecDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editPdSpecDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editPdSpecForm",
              attrs: {
                "status-icon": "",
                model: _vm.pdSpecForm,
                "label-width": "10px",
                inline: true,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "",
                    prop: "level1PackQty",
                    rules: [
                      {
                        required: true,
                        message: "请输入数量",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      min: 0,
                      max: 10000,
                      controls: "",
                      "controls-position": "right",
                      precision: 2,
                    },
                    model: {
                      value: _vm.pdSpecForm.level1PackQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.pdSpecForm, "level1PackQty", $$v)
                      },
                      expression: "pdSpecForm.level1PackQty",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "110px" },
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.dkbPdProd.specUnit,
                        callback: function ($$v) {
                          _vm.$set(_vm.dkbPdProd, "specUnit", $$v)
                        },
                        expression: "dkbPdProd.specUnit",
                      },
                    },
                    _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                      return _c("el-option", {
                        key: "specUnit" + item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "5px",
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("/")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "110px" },
                      attrs: {
                        filterable: "",
                        "filter-method": _vm.packQtyUnitFilter,
                      },
                      on: {
                        focus: function ($event) {
                          _vm.packQtyUnitDictOptions = _vm.packQtyUnitDict
                        },
                      },
                      model: {
                        value: _vm.pdSpecForm.level1PackQtyUnitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.pdSpecForm, "level1PackQtyUnitId", $$v)
                        },
                        expression: "pdSpecForm.level1PackQtyUnitId",
                      },
                    },
                    _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                      return _c("el-option", {
                        key: "specUnit" + item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "10px",
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("*")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "",
                    prop: "level2PackQty",
                    rules: [
                      { required: true, message: "请输入", trigger: "blur" },
                    ],
                  },
                },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "120px" },
                    attrs: {
                      min: 0,
                      max: 10000,
                      controls: "",
                      "controls-position": "right",
                      precision: 2,
                    },
                    model: {
                      value: _vm.pdSpecForm.level2PackQty,
                      callback: function ($$v) {
                        _vm.$set(_vm.pdSpecForm, "level2PackQty", $$v)
                      },
                      expression: "pdSpecForm.level2PackQty",
                    },
                  }),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.pdSpecForm.level1PackQtyUnitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.pdSpecForm, "level1PackQtyUnitId", $$v)
                        },
                        expression: "pdSpecForm.level1PackQtyUnitId",
                      },
                    },
                    _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                      return _c("el-option", {
                        key: "specUnit" + item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "5px",
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("/")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.dkbPdProd.packQtyUnitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dkbPdProd, "packQtyUnitId", $$v)
                        },
                        expression: "dkbPdProd.packQtyUnitId",
                      },
                    },
                    _vm._l(_vm.packQtyUnitDictOptions, function (item) {
                      return _c("el-option", {
                        key: "packQtyUnitId" + item.conceptId,
                        attrs: {
                          label: item.conceptVal,
                          value: item.conceptId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { margin: "10px 0px" },
                      attrs: { span: 24 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            type: "primary",
                            icon: "el-icon-circle-check",
                            size: "small",
                          },
                          on: { click: _vm.confirmEditPdSpec },
                        },
                        [_vm._v("确定 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            float: "right",
                            "margin-right": "10px",
                          },
                          attrs: {
                            icon: "el-icon-circle-close",
                            size: "small",
                          },
                          on: { click: _vm.cancelEditPdSpec },
                        },
                        [_vm._v("取消 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "备注信息编辑",
            width: "500px",
            "append-to-body": "",
            visible: _vm.editDrugCommentDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDrugCommentDialogVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "450px" },
            attrs: {
              type: "textarea",
              rows: 6,
              placeholder: "请输入备注信息",
              maxlength: "500",
              clearable: "",
              "show-word-limit": "",
            },
            model: {
              value: _vm.drugCommentForm.comment,
              callback: function ($$v) {
                _vm.$set(_vm.drugCommentForm, "comment", $$v)
              },
              expression: "drugCommentForm.comment",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDrugCommentDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitEditDrugComment },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
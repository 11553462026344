<template>
  <el-container style="background:#fff;height: auto;overflow: overlay;flex-direction: column;">
    <el-row style="padding: 20px;flex-grow: 1;overflow: hidden overlay;" v-loading="loading">

      <el-form ref="form" status-icon :model="dkbPdProd" label-width="120px">
        <el-col :span="24" class="title-bar">组合包装药品信息</el-col>

        <el-row>
          <el-col :span="20">
            <el-form-item label="包装药品ID:"  v-show="dkbPdProd.id">
              <el-input v-model="dkbPdProd.id" :disabled="true" style="width: 300px;float: left"/>
            </el-form-item>
            <el-form-item label="包装药品名称:" prop="drugName"
                          :rules="[{ required: true, message: '请输入包装药品名称', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入包装药品名称" maxlength="300" clearable show-word-limit v-model="dkbPdProd.drugName"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="包装规格描述:" prop="packSpecDesc"
                          :rules="[{ required: true, message: '请输入包装规格描述', trigger: 'blur'}]"
            >
              <el-input placeholder="请输入包装规格描述" maxlength="150" clearable show-word-limit
                        v-model="dkbPdProd.packSpecDesc"
                        style="width: 500px;"
              />
            </el-form-item>
          </el-col>

          <el-col :span="20">
            <el-form-item label="数量:" prop="packQty"
                          :rules="[{ required: true, message: '请输入数量', trigger: 'blur'}]"
            >
              <el-input-number v-model="dkbPdProd.packQty" :min="0" :max="100000000" controls controls-position="right"
                               :precision="2" style="width: 220px;"
              />
              <el-select v-model="dkbPdProd.specUnit"
                         style="width: 100px;"
                         filterable
                         :filter-method="packQtyUnitFilter"
                         @focus="packQtyUnitDictOptions=packQtyUnitDict"
              >
                <el-option
                  v-for="item in packQtyUnitDictOptions"
                  :key="'specUnit'+item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
              <span style="margin: 5px;font-size: 20px;font-weight: bold;">/</span>
              <el-select v-model="dkbPdProd.packQtyUnitId"
                         style="width: 100px;"
                         filterable
                         :filter-method="packQtyUnitFilter"
                         @focus="packQtyUnitDictOptions=packQtyUnitDict"
              >
                <el-option
                  v-for="item in packQtyUnitDictOptions"
                  :key="'packQtyUnitId'+item.conceptId"
                  :label="item.conceptVal"
                  :value="item.conceptId"
                ></el-option>
              </el-select>
              <el-button type="text" style="margin-left: 10px;" @click="editPdSpec">拆分</el-button>
            </el-form-item>
          </el-col>

          <!--          <el-col :span="12">
                      <el-form-item label="数量:" prop="packQty"
                                    :rules="[{ required: true, message: '请输入数量', trigger: 'blur'}]"
                      >
                        <el-input-number v-model="dkbPdProd.packQty" :min="0" :max="1000" controls controls-position="right"
                                         :precision="0" style="width: 150px;float: left"
                        />
                        <el-select v-model="dkbPdProd.packQtyUnitId"
                                   style="width: 150px;float: left"
                        >
                          <el-option
                            v-for="item in packQtyUnitDict"
                            :key="'packQtyUnitDict'+item.conceptId"
                            :label="item.conceptVal"
                            :value="item.conceptId"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>-->

<!--          <el-col :span="12">
            <el-form-item label="69条形码:" prop="barcode69">
              <el-input v-model="dkbPdProd.barcode69" style="width: 300px;" maxlength="30" clearable
                        show-word-limit
              />
            </el-form-item>
          </el-col>-->

        </el-row>

        <el-row>
          <el-col :span="24" class="title-bar">药品列表</el-col>
          <el-col :span="24" style="padding-bottom: 10px;">
            <el-button type="primary" icon="fa fa-link"
                       style="float:right;"
                       @click="queryDrugProd"
            >关联药品
            </el-button>
          </el-col>
        </el-row>

        <el-row style="padding: 0px 0px 15px 15px;">
          <el-col :span="24">
            <el-table
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :data="dkbPdProd.drugProdList"
              :height="drugProdTableHeight"
            >
              <el-table-column property="id" label="药品ID" width="80"></el-table-column>
              <el-table-column label="药品名称">
                <template slot-scope="scope">
                  <span style="margin-right: 5px;">{{ scope.row.drugProdName }}</span>
                  <span v-for="item in scope.row.drugCategoryList"
                        :key="item.id"
                  >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column property="approvalNum" label="批准文号"></el-table-column>
              <el-table-column property="manufacturerName" label="生产企业"></el-table-column>
              <el-table-column property="specDesc" label="规格描述"></el-table-column>

              <el-table-column label="操作" width="150">
                <template slot-scope="scope">
                  <el-button @click="delDrugProd(scope.$index,scope.row)" type="text" style="color: #FF3333;"
                             size="small"
                  >删除
                  </el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" class="title-bar">包装材料列表</el-col>
          <el-col :span="24" style="padding-bottom: 10px;">
            <el-button type="primary"
                       style="float:right;"
                       @click="editPdProdMat()"
            >添加包装材料
            </el-button>
          </el-col>
        </el-row>


        <el-row style="padding: 0px 0px 15px 15px;margin-bottom: 50px;">
          <el-col :span="24">
            <el-table
              border
              stripe
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :data="dkbPdProd.pdProdMatList"
              :height="matTableHeight"
            >
              <el-table-column property="id" label="包装材料ID" width="100"></el-table-column>
              <el-table-column property="wrapperDesc" label="包装材料描述" min-width="100"></el-table-column>
              <el-table-column property="storage" label="储藏条件" width="100"></el-table-column>
              <el-table-column
                label="有效期"
                width="80"
              >
                <template slot-scope="scope">
                  {{ scope.row.indate }} {{ scope.row.indateUnitDesc }}
                </template>
              </el-table-column>
              <el-table-column property="barcode69" label="编码" min-width="200">
                <template slot-scope="scope">
                  <p v-show="scope.row.barcode69">69条形码: {{ scope.row.barcode69 }}</p>
                  <p v-show="scope.row.stateMedicareNumList.toString()!=''">国家医保统编码:
                    <span  v-for="(item,index) in scope.row.stateMedicareNumList" :key="item+'stateMedicareNum'">
              {{ item}}<span v-show="index<scope.row.stateMedicareNumList.length-1">;</span>
            </span>
                  </p>
                  <p v-show="scope.row.shMedicareNumList.toString()!=''">上海医保统编码:
                    <span  v-for="(item,index) in scope.row.shMedicareNumList" :key="item+'shMedicareNum'">
              {{ item}}<span v-show="index<scope.row.shMedicareNumList.length-1">;</span>
            </span>
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <template slot-scope="scope">
                  <el-button @click="editPdProdMat(scope.$index,scope.row)" type="text" size="small">编辑</el-button>
                  <el-button @click="delPdProdMat(scope.$index,scope.row)" type="text" style="color: #FF3333;"
                             size="small"
                  >删除
                  </el-button>
                </template>
              </el-table-column>
              <template slot="empty">
                <img src="@/assets/images/no-booking.svg" alt="">
                <p>暂无数据</p>
              </template>
            </el-table>
          </el-col>
        </el-row>



      </el-form>
    </el-row>

    <el-row style="margin-bottom: 10px;">
      <el-col :span="20" class="bottom-bar" style="height: 80px;padding-right: 0px;position: fixed;right: 30px;bottom: 10px;">
        <el-button style="float: right;margin-left: 10px;" @click="editNext" v-show="dkbPdProd.id">下一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="editPrev" v-show="dkbPdProd.id">上一个</el-button>
        <el-button style="float: right;margin-left: 10px;" @click="handleReturn">返回
        </el-button>
        <el-button type="primary" style="float: right" @click="submitForm(1)">保存</el-button>
        <el-button type="primary" style="float: right" @click="submitForm(2)">保存并关闭</el-button>
      </el-col>
    </el-row>




    <el-dialog v-dialogDrag title="药品检索" append-to-body width="60%" :visible.sync="drugProd.dialogVisible"
               :close-on-click-modal="false"
    >
      <el-form status-icon :model="drugProd">
        <el-form-item>
          <el-input placeholder="请输入关键字" v-model="drugProd.searchValue" maxlength="20" clearable
                    show-word-limit style="width: 400px;float: left" @keydown.enter.native="queryDrugProd"
          />
          <el-input style="display: none"/>
          <el-button type="primary" icon="el-icon-search" style="margin-left: 20px;background: #2B60F8"
                     @click="queryDrugProd"
          >检索
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        :data="drugProdTableData"
        :height="300"
      >
        <el-table-column property="id" label="药品ID" width="100"></el-table-column>
        <el-table-column property="drugProdName" label="药品名称"></el-table-column>
        <el-table-column property="approvalNum" label="批准文号"></el-table-column>
        <el-table-column property="manufacturerName" label="生产企业"></el-table-column>
        <el-table-column property="specDesc" label="规格描述"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini" type="text"
              @click="chooseDrugProd(scope.$index, scope.row)"
            >选择
            </el-button>
          </template>
        </el-table-column>
        <template slot="empty" v-if="isDrugProd === false">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
      <pagination
        v-show="drugProd.total>0"
        :total="drugProd.total"
        :page.sync="drugProd.pageNum"
        :limit.sync="drugProd.pageSize"
        @pagination="queryDrugProd"
      />
    </el-dialog>


    <el-dialog v-dialogDrag title="编辑包装材料" width="600px" append-to-body :visible.sync="editPdProdMatDialogVisible"
               :close-on-click-modal="false"
    >
      <el-row style="padding: 20px;">
        <el-form ref="editPdProdMatForm" status-icon :model="editPdProdMatForm" label-width="130px">

          <el-row>
            <el-col :span="24">
              <el-form-item label="包装材料描述:" prop="wrapperDesc"
                            :rules="[
                              { required: true, message: '请输入包装材料描述', trigger: 'blur'},
                              { validator: validateWrapperDesc, trigger: 'blur'}]"
              >
                <el-input type="textarea" :rows="6" placeholder="请输入包装材料描述"
                          v-model="editPdProdMatForm.wrapperDesc"
                          maxlength="1000" clearable show-word-limit
                          style="width: 400px;float: left;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="储藏条件:" prop="storage">
                <el-input v-model="editPdProdMatForm.storage" style="width: 400px;" maxlength="200" clearable show-word-limit/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="有效期:" prop="indate"
                            :rules="[]"
              >
                <el-input-number v-model="editPdProdMatForm.indate" :min="0" :max="10000" controls controls-position="right"
                                 :precision="0" style="width: 120px;float: left"
                />
                <el-select v-model="editPdProdMatForm.indateUnit"
                           style="width: 100px;float: left"
                >
                  <el-option
                    v-for="item in timeUnitDict"
                    :key="'indateUnit'+item.conceptId"
                    :label="item.conceptVal"
                    :value="item.conceptId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="69条形码:" prop="barcode69">
                <el-input v-model="editPdProdMatForm.barcode69" style="width: 400px;" maxlength="30" clearable
                          show-word-limit
                />
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item
                v-for="(item, index) in editPdProdMatForm.stateMedicareNumList"
                :label="index==0?'国家医保统编码:':''"
                :key="'stateMedicareNum-'+index"
                :rules="[ { validator: validateMedicareNum, trigger: 'blur'}]"

                :prop="'stateMedicareNumList.' + index"
              >
                <el-input placeholder="请输入国家医保统编码" v-model="editPdProdMatForm.stateMedicareNumList[index]"
                          style="width: 320px;float: left"
                          maxlength="30" clearable show-word-limit
                          @paste.native.capture.prevent="item==''"
                          @focus="medicareNumInputFocus('国家',index)"
                />
                <i class="el-icon-remove"
                   v-show="editPdProdMatForm.stateMedicareNumList.length>1"
                   @click.prevent="delMedicareNum(item,index,'国家')"></i>
                <i class="el-icon-circle-plus"
                   v-show="index==editPdProdMatForm.stateMedicareNumList.length-1 && editPdProdMatForm.stateMedicareNumList.length<5"
                   style="font-size: 20px;color: #2B60F8;margin-left: 4px;" @click.prevent="addMedicareNum('国家')"></i>

              </el-form-item>

            </el-col>


            <el-col :span="24">
              <el-form-item
                v-for="(item, index) in editPdProdMatForm.shMedicareNumList"
                :label="index==0?'上海医保统编码:':''"
                :key="'shMedicareNum-'+index"
                :rules="[ { validator: validateMedicareNum, trigger: 'blur'}]"

                :prop="'shMedicareNumList.' + index"
              >
                <el-input placeholder="请输入上海医保统编码" v-model="editPdProdMatForm.shMedicareNumList[index]"
                          style="width: 320px;float: left"
                          maxlength="30" clearable show-word-limit
                          @paste.native.capture.prevent="item==''"
                           @focus="medicareNumInputFocus('上海',index)"
                />
                <i class="el-icon-remove"
                   v-show="editPdProdMatForm.shMedicareNumList.length>1"
                   @click.prevent="delMedicareNum(item,index,'上海')"></i>
                <i class="el-icon-circle-plus"
                   v-show="index==editPdProdMatForm.shMedicareNumList.length-1 && editPdProdMatForm.shMedicareNumList.length<5"
                   style="font-size: 20px;color: #2B60F8;margin-left: 4px;" @click.prevent="addMedicareNum('上海')"></i>

              </el-form-item>

            </el-col>





          </el-row>

          <el-row>
            <el-col :span="24">
              <el-button type="primary"
                         size="small"
                         style="margin-left: 20px;background: #2B60F8;float:right;"
                         @click="confirmPdProdMat"
              >确定
              </el-button>
              <el-button style="float: right;margin-right: 10px;"
                         size="small" @click="editPdProdMatDialogVisible=false"
              >取消
              </el-button>
            </el-col>
          </el-row>

        </el-form>
      </el-row>
    </el-dialog>

    <el-dialog v-dialogDrag title="国家医保药品目录" width="80%" append-to-body :visible.sync="nhsaDrugDictDialogVisible"
               :close-on-click-modal="false"
    >
      <div>
        <el-form ref="drugDictQueryForm" status-icon :model="dictDrugForm">
          <el-form-item>
            <el-input placeholder="请输入药品名称/批准文号/本位码" clearable v-model="dictDrugForm.drugName"
                      show-word-limit style="width: 300px;float: left"
                      @keydown.enter.native="selectDictDrug('国家')"
            />
            <el-input style="display: none"/>
            <el-button type="primary" icon="el-icon-search" @click="selectDictDrug('国家')"
                       style="margin-left: 20px;background: #2B60F8"
            >检索
            </el-button>
          </el-form-item>
        </el-form>
        <el-table :data="dictDrugTableData"
                  :height="300" v-loading="loading"
                  border
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  @sort-change="drugDictTableSortChange"
                  stripe
        >
          <el-table-column min-width="150" property="registeredProductName" label="药品名称" sortable="custom"
          ></el-table-column>
          <el-table-column property="displayName" label="规格剂型" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.registeredMedicineModel }}{{ scope.row.registeredOutlook }}
            </template>
          </el-table-column>
          <el-table-column min-width="100" property="realitySpec" label="实际规格" ></el-table-column>
          <el-table-column min-width="150" property="materialName" label="包装">
            <template slot-scope="scope">
              {{ scope.row.materialName }}({{ scope.row.factor }}/{{ scope.row.minUnit }}/{{
                scope.row.unit
              }})
            </template>
          </el-table-column>
          <el-table-column min-width="150" property="companyNamesc" label="厂家" sortable="custom"
          ></el-table-column>
          <el-table-column min-width="100" property="approvalCode" label="批准文号" sortable="custom"></el-table-column>
          <el-table-column min-width="150" property="goodsStandardCode" label="本位码" sortable="custom"></el-table-column>
          <el-table-column min-width="150" property="goodsCode" label="国家医保编码" sortable="custom"></el-table-column>
          <el-table-column min-width="80" property="comment" label="备注信息" ></el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="selectDrugDictTableRow(nowEditInputIndex, scope.row,'国家')"
              >选择
              </el-button>
              <el-button
                size="mini" type="text"
                @click="editDrugComment(scope.row,1)"
              >编辑备注
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="dictDrugForm.total>0"
          :total="dictDrugForm.total"
          :page.sync="dictDrugForm.pageNum"
          :limit.sync="dictDrugForm.pageSize"
          @pagination="selectDictDrug('国家')"
        />
      </div>
    </el-dialog>

    <el-dialog v-dialogDrag title="上海医保药品目录" width="80%" append-to-body :visible.sync="smpaDrugDictDialogVisible"
               :close-on-click-modal="false"
    >
      <div>
        <el-form ref="drugDictQueryForm" status-icon :model="dictDrugForm">
          <el-form-item>
            <el-input placeholder="请输入药品名称/批准文号/本位码" clearable v-model="dictDrugForm.drugName"
                      show-word-limit style="width: 300px;float: left"
                      @keydown.enter.native="selectDictDrug('上海')"
            />
            <el-input style="display: none"/>
            <el-button type="primary" icon="el-icon-search" @click="selectDictDrug('上海')"
                       style="margin-left: 20px;background: #2B60F8"
            >检索
            </el-button>
          </el-form-item>
        </el-form>
        <el-table :data="dictDrugTableData"
                  :height="300" v-loading="loading"
                  border
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  @sort-change="drugDictTableSortChange"
                  stripe
        >
          <el-table-column min-width="150" property="yptym" label="药品名称" sortable="custom"></el-table-column>
          <el-table-column property="displayName" label="剂型规格包装" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.jx }}--{{ scope.row.ggbz }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" property="ggbzwzbs" label="包装"></el-table-column>
          <el-table-column min-width="150" property="scqy" label="厂家" sortable="custom"></el-table-column>
          <el-table-column min-width="100" property="pzwh" label="批准文号" sortable="custom"></el-table-column>
          <el-table-column min-width="150" property="bwm" label="本位码" sortable="custom"></el-table-column>
          <el-table-column min-width="150" property="tbdm" label="上海医保编码" sortable="custom"></el-table-column>
          <el-table-column min-width="80" property="comment" label="备注信息" ></el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="selectDrugDictTableRow(nowEditInputIndex, scope.row,'上海')"
              >选择
              </el-button>
              <el-button
                size="mini" type="text"
                @click="editDrugComment(scope.row,2)"
              >编辑备注
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="dictDrugForm.total>0"
          :total="dictDrugForm.total"
          :page.sync="dictDrugForm.pageNum"
          :limit.sync="dictDrugForm.pageSize"
          @pagination="selectDictDrug('上海')"
        />
      </div>
    </el-dialog>

    <el-dialog v-dialogDrag title="包装药品明细拆分" width="60%" append-to-body :visible.sync="editPdSpecDialogVisible"
               :close-on-click-modal="false"
    >
      <el-form ref="editPdSpecForm" status-icon :model="pdSpecForm" label-width="10px" :inline="true">
        <el-form-item label="" prop="level1PackQty"
                      :rules="[{ required: true, message: '请输入数量', trigger: 'blur'}]"
        >
          <el-input-number v-model="pdSpecForm.level1PackQty" :min="0" :max="10000" controls controls-position="right"
                           :precision="2" style="width: 120px;"
          />
          <el-select v-model="dkbPdProd.specUnit"
                     style="width: 110px;"
                     disabled
          >
            <el-option
              v-for="item in packQtyUnitDictOptions"
              :key="'specUnit'+item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <span style="margin: 5px;font-size: 20px;font-weight: bold;">/</span>
          <el-select v-model="pdSpecForm.level1PackQtyUnitId"
                     style="width: 110px;"
                     filterable
                     :filter-method="packQtyUnitFilter"
                     @focus="packQtyUnitDictOptions=packQtyUnitDict"
          >
            <el-option
              v-for="item in packQtyUnitDictOptions"
              :key="'specUnit'+item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <span style="margin: 10px;font-size: 20px;font-weight: bold;">*</span>

        </el-form-item>

        <el-form-item   label="" prop="level2PackQty"
                      :rules="[{ required: true, message: '请输入', trigger: 'blur'}]"
        >
          <el-input-number v-model="pdSpecForm.level2PackQty" :min="0" :max="10000" controls controls-position="right"
                           :precision="2" style="width: 120px;"
          />
          <el-select v-model="pdSpecForm.level1PackQtyUnitId"
                     style="width: 100px;"
                     disabled
          >
            <el-option
              v-for="item in packQtyUnitDictOptions"
              :key="'specUnit'+item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
          <span style="margin: 5px;font-size: 20px;font-weight: bold;">/</span>
          <el-select v-model="dkbPdProd.packQtyUnitId"
                     style="width: 100px;"
                     disabled
          >
            <el-option
              v-for="item in packQtyUnitDictOptions"
              :key="'packQtyUnitId'+item.conceptId"
              :label="item.conceptVal"
              :value="item.conceptId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="24" style="margin: 10px 0px;">
            <el-button type="primary" style="float: right;margin-right: 10px;" icon="el-icon-circle-check"
                       size="small" @click="confirmEditPdSpec"
            >确定
            </el-button>
            <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-close"
                       size="small" @click="cancelEditPdSpec"
            >取消
            </el-button>
          </el-col>
        </el-row>
      </el-form>

    </el-dialog>

    <el-dialog v-dialogDrag title="备注信息编辑" width="500px" append-to-body
               :visible.sync="editDrugCommentDialogVisible"
               :close-on-click-modal="false"
    >
      <el-input type="textarea" :rows="6" placeholder="请输入备注信息"
                v-model="drugCommentForm.comment"
                maxlength="500" clearable show-word-limit
                style="width: 450px;"
      />
      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="editDrugCommentDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitEditDrugComment">确 定</el-button>
      </span>
    </el-dialog>


  </el-container>
</template>
<script>

import conceptConst from '@/const/conceptConst'
import { getConcept } from '@/utils/concept'
import { comboDrugInfo, saveOrUpdateComboDrug,comboDrugList } from '@/api/dkm/pkgDrugProd'
import { list } from '@/api/dkm/drugProd'
import { deepClone } from '../../../utils'
import { smpaDrugList, nhsaDrugList,checkDrugHasRelevance,updateDrugComment,selectNhsaToSmpaDrugMapping } from '@/api/dkm/dictDrug'
import { isEmpty } from '@/utils/common'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
export default {
  name: 'PkgDrugProdEdit',
  watch: {
    /*监听dkbPdProd值的变化*/
    dkbPdProd: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.editTimes == 0) {
          this.editTimes++
        } else {
          //console.log('dkbDp数据变化了')
          this.editFlag = true
        }

      }
    }

  },
  data() {
    return {
      conceptConst,
      loading: false,
      dkbPdProd: {
        drugName: undefined,
        packType: 2,
        packSpecDesc: undefined,
        storage: undefined,
        indate: undefined,
        indateUnit: undefined,
        packQty: undefined,
        specUnit:undefined,
        packQtyUnitId: undefined,
        packQtyUnitDesc: undefined,
        barcode69: undefined,
        shMedicareNum: undefined,
        stateMedicareNum: undefined,
        drugProdList: [],
        pdProdMatList:[],
        //包装明细拆分
        dkbPdSpec:{}
      },
      /* 标志数据是否编辑过*/
      editFlag: false,
      /* 数据编辑次数*/
      editTimes: 0,
      /*时间单位字典*/
      timeUnitDict: [],
      /*包装数量单位字典*/
      packQtyUnitDict: [],
      packQtyUnitDictOptions: [],

      /*选择药品表格数据*/
      drugProdTableData: [],
      /*药品列表检索相关*/
      drugProd: {
        /* 输入框值*/
        searchValue: '',
        /*控制弹窗显示隐藏*/
        dialogVisible: false,
        pageNum: 1,
        pageSize: 10,
        /*总条数*/
        total: 0
      },

      isDrugProd: false,
      /*编辑包装材料弹窗显示隐藏*/
      editPdProdMatDialogVisible:false,
      /*编辑包装材料表单*/
      editPdProdMatForm:{},
      /*国家医保药品目录和上海医保药品目录字典表格数据*/
      dictDrugTableData:[],
      dictDrugForm:{
        drugName:'',
        pageNum:1,
        pageSize:10,
        total:0,
      },
      /*药品字典表格弹框显示隐藏*/
      nhsaDrugDictDialogVisible:false,
      smpaDrugDictDialogVisible:false,
      nowEditInputIndex:undefined,
      drugDictType:'',
      /*列表页面查询参数*/
      queryParams:{},
      /*存储根据查询获取到的数据，用于翻页*/
      pageData:[],
      /*包装明细拆分弹窗*/
      editPdSpecDialogVisible: false,
      /*包装药品明细表单*/
      pdSpecForm: {},
      //医保药品备注信息编辑弹窗
      editDrugCommentDialogVisible:false,
      //医保药品备注信息编辑表单
      drugCommentForm:{
        drugId:"",
        comment:"",
        drugType:""
      }

    }
  },
  mounted: function() {
  },
  created() {
    this.loading = true
    // 直接打开的新增、编辑页面
    const id = this.$route.params && this.$route.params.id
    if (id) {
      this.queryParams=getCache("pkgDrugProdQueryParams")
      this.pageData=getCache("pkgDrugProdPageData")
      if(!this.queryParams){
        this.queryParams={}
      }
      if(!this.queryParams.pageNum){
        this.queryParams.pageNum=1
      }
      if(!this.queryParams.pageSize){
        this.queryParams.pageSize=10
      }
      this.init().then(result => {
        this.getComboDrugInfo(id)
      })
    } else {
      this.init().then(result => {
        //有效期单位，设置默认值
        if (this.timeUnitDict && this.timeUnitDict.length > 0) {
          this.dkbPdProd.indateUnit = this.timeUnitDict[0].conceptId
        }
        //数量单位，设置默认值
        if (this.packQtyUnitDict && this.packQtyUnitDict.length > 0) {
          this.dkbPdProd.specUnit = this.packQtyUnitDict[0].conceptId
          this.dkbPdProd.packQtyUnitId = this.packQtyUnitDict[0].conceptId
          if(this.dkbPdProd.dkbPdSpec){
            this.dkbPdProd.dkbPdSpec.level1PackQtyUnitId= conceptConst.PUB_SPEC_PKG_UNIT_BOARD
          }
        }

        this.loading = false
      })

    }

  },
  computed: {

    /* 计算属性--药品列表表格高度*/
    drugProdTableHeight() {
      let drugProdList = this.dkbPdProd.drugProdList
      let row = drugProdList ? drugProdList.length : 0
      let height = 0
      height = 100 + (row - 1) * 50
      if (height < 250) {
        return 250
      } else if (height > 400) {
        return 400
      } else {
        return height
      }
    },
    /* 计算属性--包装材料列表表格高度*/
    matTableHeight() {
      let pdProdMatList = this.dkbPdProd.pdProdMatList
      let row = pdProdMatList ? pdProdMatList.length : 0
      let height = 0
      height = 100 + (row - 1) * 50
      if (height < 250) {
        return 250
      } else if (height > 400) {
        return 400
      } else {
        return height
      }
    }
  },
  methods: {
    /*初始化--加载字典等*/
    async init() {

      /*获取有效期时间单位字典*/
      await getConcept(conceptConst.PUB_TIME_UNIT_EXPIRATION, 0).then(result => {
        this.timeUnitDict = result
      })



      /**规格包装单位概念域（片、粒、袋、支、瓶、盒等）*/
      await getConcept(conceptConst.PUB_SPEC_PKG_UNIT, 0).then(result => {
        this.packQtyUnitDict = result
        this.packQtyUnitDictOptions = result
      })

    },
    /*获取组合包装药品详细信息*/
    getComboDrugInfo(id) {
      comboDrugInfo(id).then(response => {
        if (!response.data) {
          this.msgError('获取组合包装药品信息失败')
          return
        }
        this.initComboDrugInfo(response.data)
      })
    },
    /*处理获取到的组合包装药品详细信息*/
    initComboDrugInfo(info) {
      Object.assign(this.dkbPdProd, info)
      if (!this.dkbPdProd.indate) {
        this.dkbPdProd.indate = undefined
      }
      // 处理药品列表
      let drugProdList=this.dkbPdProd.drugProdList
      if(drugProdList){
        this.initDrugProdData(drugProdList)
      }else{
        drugProdList=[]
      }
      //处理包装明细拆分数据
      let dkbPdSpec=this.dkbPdProd.dkbPdSpec
      if(dkbPdSpec){
        // 解决值为null时，默认input输入框显示值为0的问题
        dkbPdSpec.level1PackQty=dkbPdSpec.level1PackQty==null?undefined:dkbPdSpec.level1PackQty
        dkbPdSpec.level2PackQty=dkbPdSpec.level2PackQty==null?undefined:dkbPdSpec.level2PackQty

      }
      this.loading = false
    },

    /*删除药品*/
    delDrugProd(index, row) {
      let drugProdList = this.dkbPdProd.drugProdList
      if (drugProdList.length == 1) {
        this.$message('药品至少保留一个！')
      } else {
        let that = this
        this.$confirm('是否删除药品[' + row.drugProdName + ']?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function() {
          drugProdList.splice(index, 1)
        })

      }
    },

    /*药品检索*/
    queryDrugProd() {
      this.isDrugProd = true
      this.loading = true
      this.drugProd.dialogVisible = true
      list(this.drugProd).then(response => {
        this.drugProdTableData = response.rows
        this.initDrugProdData(this.drugProdTableData)
        this.drugProd.total = response.total
        this.loading = false
      })
    },
    /*处理查询到的药品数据*/
    initDrugProdData(data) {
      if (data == undefined) {
        return
      }
      data.forEach((item) => {
        //药品别名
        item.drugProdName = ''
        if (item.dkbDpAliasList) {
          item.dkbDpAliasList.forEach((aliasItem) => {
            if (aliasItem.isPrimary && aliasItem.isPrimary == '1') {
              item.drugProdName = aliasItem.alias
              return
            }
          })
        }
        //批准文号
        item.approvalNum = ''
        if (item.dkbDpApprovalNumList) {
          item.dkbDpApprovalNumList.forEach((approvalNumItem) => {
            if (approvalNumItem.approvalNum) {
              item.approvalNum += approvalNumItem.approvalNum + ';'
            }
          })
          item.approvalNum=item.approvalNum.substr(0,item.approvalNum.length-1)
        }
        //规格描述
        item.specDesc = ''
        if (item.dkbDpSpecDescList) {
          item.dkbDpSpecDescList.forEach((specDescItem) => {
            if (specDescItem.specDesc) {
              item.specDesc += specDescItem.specDesc + ';'
            }
          })
          item.specDesc=item.specDesc.substr(0,item.specDesc.length-1)
        }

        //生产企业
        item.manufacturerName=''
        if (item.dkbDpManufacturerList) {
          item.dkbDpManufacturerList.forEach((manufacturerItem) => {
            if (manufacturerItem.manufacturerName) {
              item.manufacturerName += manufacturerItem.manufacturerName+";"
            }
          })
          item.manufacturerName=item.manufacturerName.substr(0,item.manufacturerName.length-1)
        }
      })
    },
    /*选中一条药品检索表格数据*/
    chooseDrugProd(index, row) {
      let drugProdItem={}
      drugProdItem.id = row.id
      drugProdItem.drugProdName = row.drugProdName
      drugProdItem.approvalNum = row.approvalNum
      drugProdItem.manufacturerName = row.manufacturerName
      drugProdItem.specDesc = row.specDesc
      this.drugProd.dialogVisible = false
      this.dkbPdProd.drugProdList.push(drugProdItem)
    },

    /*根据conceptId从字典中获取概念值的描述*/
    getConceptVal(dict, conceptId) {
      let conceptVal
      dict.forEach(function(item) {
        if (item.conceptId === conceptId) {
          conceptVal = item.conceptVal
        }
      })
      return conceptVal
    },
    /*返回按钮*/
    handleReturn() {
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route)
      // 返回上一步路由
      this.$router.push({ path:'/dkm/pkgDrugProd' })
    },
    /*保存按钮--保存组合包装药品*/
    submitForm(type) {
      if (this.editFlag == false) {
        this.msgInfo('数据没有改变，无需保存')
        return
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (!this.dkbPdProd.indate) {
            this.dkbPdProd.indate = undefined
            this.dkbPdProd.indateUnit = undefined
          }
          saveOrUpdateComboDrug(this.dkbPdProd).then(response => {
            this.msgSuccess(response.msg)
            //调用刷新表格数据的方法
            AcrossUtil.$emit('tableReload');
            if(type==2){
              // 关闭当前tab页
              this.$store.dispatch('tagsView/delView', this.$route)
              // 返回上一步路由
              this.$router.push({ path:'/dkm/pkgDrugProd' })
            }else{
              if(response.data){
                //保存完成后根据id跳转到编辑界面
                this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
                  this.$nextTick(() => {
                    this.$router.replace({
                      path: '/redirect' + '/pkgDrugProd/edit/' + response.data
                    })
                  })
                })
              }
            }

          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /* 包装数量单位--下拉搜索过滤*/
    packQtyUnitFilter(query) {
      if (query !== '') {
        this.packQtyUnitDictOptions = this.packQtyUnitDict.filter(item => {
          let flag= item.conceptVal.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.spell.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
            || item.wubi.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          //概念别名过滤
          if(item.aliasList){
            item.aliasList.some(aliasItem=>{
              if((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase())>-1)
                || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase())>-1)
              ){
                flag=true
                return true
              }
            })
          }
          return flag
        })

      } else {
        this.packQtyUnitDictOptions = this.packQtyUnitDict
      }
    },
    /*添加/编辑包装材料*/
    editPdProdMat(index,row) {
      this.initEditPdProdMatForm()
      if(row){
        //编辑
        this.editPdProdMatForm=deepClone(row)
        if(this.editPdProdMatForm.indate==null){
          //解决数字类型字段为null时，前端数字类型输入框显示为0
          this.editPdProdMatForm.indate=undefined
        }
        this.editPdProdMatForm.index = index
      }
      // 编辑时候 默认填充有效期单位为月
      if (this.timeUnitDict && this.timeUnitDict.length > 0 && this.editPdProdMatForm.indateUnit==undefined) {
        this.editPdProdMatForm.indateUnit =conceptConst.PUB_TIME_UNIT_EXPIRATION_MONTH
      }
      if(this.editPdProdMatForm.stateMedicareNumList.length==0){
        this.editPdProdMatForm.stateMedicareNumList.push("")
      }
      if(this.editPdProdMatForm.shMedicareNumList.length==0){
        this.editPdProdMatForm.shMedicareNumList.push("")
      }
      this.editPdProdMatDialogVisible=true
    },
    /*初始化包装材料编辑表单字段*/
    initEditPdProdMatForm() {
      this.editPdProdMatForm = {
        index: undefined,
        wrapperDesc:'',
        storage: '',
        indate: undefined,
        indateUnit: undefined,
        barcode69: '',
        stateMedicareNum: '',
        stateMedicareNumList: [],
        shMedicareNum: '',
        shMedicareNumList: []
      }
    },
    /*删除包装材料*/
    delPdProdMat(index,row) {
      this.dkbPdProd.pdProdMatList.splice(index, 1)
    },
    /*包装材料编辑表单弹窗--确定*/
    confirmPdProdMat(){
      this.$refs['editPdProdMatForm'].validate(valid => {
        if (valid) {
          let formData=deepClone(this.editPdProdMatForm)
          this.editFlag = true
          //处理有效期单位，有效期没有值时，将有效期单位清空
          if (!formData.indate) {
            formData.indateUnitDesc = undefined
            formData.indateUnit = undefined
          }else{
            //处理字典项-有效期单位
            formData.indateUnitDesc = this.getConceptVal(this.timeUnitDict, formData.indateUnit)
          }

          if(formData.index!=undefined){
            //编辑
            this.dkbPdProd.pdProdMatList[formData.index]=formData
          }else{
            //新增
            this.dkbPdProd.pdProdMatList.push(formData)
          }
          this.editPdProdMatForm={}
          this.editPdProdMatDialogVisible=false
        }else {
          this.msgError('请规范填写包装材料信息')
        }
      })
    },
    /*查询药品目录*/
    selectDictDrug(type){
      this.loading = true
      if(type==='国家'){
        nhsaDrugList(this.dictDrugForm).then(response => {
          this.dictDrugTableData=response.rows
          this.dictDrugForm.total=response.total
          this.loading = false
        })
      }else if(type==='上海'){
        smpaDrugList(this.dictDrugForm).then(response => {
          this.dictDrugTableData=response.rows
          this.dictDrugForm.total=response.total
          this.loading = false
        })
      }

    },
    /*药品目录表格排序切换*/
    drugDictTableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.dictDrugForm.orderByColumn=prop
      this.dictDrugForm.isAsc=order
      this.selectDictDrug(this.drugDictType)
    },

    /* 国家医保统编码和上海医保统编码输入框 获得焦点时触发*/
    medicareNumInputFocus(type,index){
      if(type==='国家'){
        this.nhsaDrugDictDialogVisible=true
        this.smpaDrugDictDialogVisible=false
      }else if(type==='上海'){
        this.smpaDrugDictDialogVisible=true
        this.nhsaDrugDictDialogVisible=false
      }
      this.nowEditInputIndex=index
      this.drugDictType=type
      this.dictDrugForm.drugName=''
      this.dictDrugForm.orderByColumn=''
      this.dictDrugForm.isAsc=''
      this.dictDrugForm.pageNum=1
      this.selectDictDrug(this.drugDictType);
    },
    /*药品字典表格--选中一行*/
    async selectDrugDictTableRow(index, row, type) {
      this.smpaDrugDictDialogVisible = false
      this.nhsaDrugDictDialogVisible = false
      let drugType = type === '国家' ? 1 : 2
      let drugCode = type === '国家' ? row.goodsCode : row.tbdm
      let _this = this
      let checkPass = await this.checkDrugCodeHasRelevance(drugType, drugCode,true)
      if(checkPass==true){
        if(type==='国家'){
          _this.editPdProdMatForm.stateMedicareNumList[index] =drugCode
        }else{
          _this.editPdProdMatForm.shMedicareNumList[index] =drugCode
        }
        //根据国家医保补充上海医保 或 根据上海医保填充国家医保
        _this.autoFillNhsaOrSmpaDrugCode(drugCode,drugType)
      }else{
        if(type==='国家'){
          _this.editPdProdMatForm.stateMedicareNumList[index] =''
        }else{
          _this.editPdProdMatForm.shMedicareNumList[index] =''
        }
      }
      this.$forceUpdate()

    },
    async editNext(){
      if(this.pageData==undefined){
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
          this.$router.push('/dkm/pkgDrugProd')
          return
        })
      }
      let nowIndex=-1
      let id=this.dkbPdProd.id
      this.pageData.some((item,index)=>{
        if(item.id==id){
          nowIndex=index
          return true
        }
      })
      if(nowIndex>-1 && nowIndex< this.pageData.length-1){
        id=this.pageData[nowIndex+1].id
      }else{
        //查询下一页数据
        this.queryParams.pageNum++
        //向上取整
        let maxPage=Math.ceil(this.queryParams.total/this.queryParams.pageSize)
        if (this.queryParams.pageNum>maxPage){
          this.msgInfo('已经是最后一条记录了')
          this.queryParams.pageNum--
          return;
        }
        let response=await comboDrugList(this.queryParams)
        this.queryParams.total=response.total
        setCache("pkgDrugProdQueryParams",this.queryParams)
        setCache("pkgDrugProdPageData",response.rows)
        this.pageData= response.rows
        id=this.pageData[0].id
      }
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
        this.$router.push('/pkgDrugProd/edit/' + id)
      })
    },
    async editPrev(){
      if(this.pageData==undefined){
        //刷新页面直接进入edit页面，点击下一页上一页跳转到列表页面
        this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
          this.$router.push('/dkm/pkgDrugProd')
          return
        })
      }
      let nowIndex=-1
      let id=this.dkbPdProd.id
      this.pageData.some((item,index)=>{
        if(item.id==id){
          nowIndex=index
          return true
        }
      })
      //console.log(id,this.pageData,nowIndex,"-----",this.queryParams,this.queryParams.pageNum)
      if(nowIndex>0 ){
        id=this.pageData[nowIndex-1].id
      }else{
        //查询上一页数据
        this.queryParams.pageNum--
        if(this.queryParams.pageNum<1){
          this.msgInfo('已经是第一条记录了')
          this.queryParams.pageNum++
          return;
        }
        let response=await comboDrugList(this.queryParams)
        this.queryParams.total=response.total
        setCache("pkgDrugProdQueryParams",this.queryParams)
        setCache("pkgDrugProdPageData",response.rows)
        this.pageData= response.rows
        id=this.pageData[response.rows.length-1].id
      }
      // 关闭当前tab页
      this.$store.dispatch('tagsView/delView', this.$route).then(res=>{
        this.$router.push('/pkgDrugProd/edit/' + id)
      })

    },
    /*删除一个医保编码*/
  delMedicareNum(item,index,type){
    if (type === '国家') {
      this.editPdProdMatForm.stateMedicareNumList.splice(index, 1)
    } else if (type === '上海') {
      this.editPdProdMatForm.shMedicareNumList.splice(index, 1)
    }
  },
  /*添加一个医保编码*/
  addMedicareNum(type){
    if (type === '国家') {
      this.editPdProdMatForm.stateMedicareNumList.push("")
    } else if (type === '上海') {
      this.editPdProdMatForm.shMedicareNumList.push("")
    }
  },
    /*国家医保和上海医保输入框校验*/
    validateMedicareNum(rule, value, callback, index){
      //1.单个包装药品的不能重复输入
      //2.非必填项，但是不能空输入框只能保留一个
      let key = Object.keys(index)[0]
      let medicareNumIndex = key.substr(key.indexOf(".")+1)
      let medicareNumList
      if(key.indexOf("stateMedicareNumList")>=0){
        medicareNumList=this.editPdProdMatForm.stateMedicareNumList
      }else{
        medicareNumList=this.editPdProdMatForm.shMedicareNumList
      }
      value=medicareNumList[medicareNumIndex]

      if(medicareNumIndex>0 && isEmpty(value)){
        //检查空值
        callback(new Error('请输入'))
      }else if(!isEmpty(value)){
        //检查重复项
        medicareNumList.forEach((item,i)=>{
          if(item.trim()===value && i!=medicareNumIndex){
            callback(new Error('请勿重复输入'))
          }
        })
        callback()
      }else{
        callback()
      }

    },
    /*拆分包装药品*/
    editPdSpec() {
      this.editPdSpecDialogVisible = true
      this.pdSpecForm=deepClone(this.dkbPdProd.dkbPdSpec)
    },
    /*包装明细拆分弹窗--确定按钮*/
    confirmEditPdSpec() {
      this.$refs['editPdSpecForm'].validate(valid => {
        if (valid) {
          let source = this.pdSpecForm
          this.dkbPdProd.dkbPdSpec=deepClone(source)
          this.dkbPdProd.dkbPdSpec.level2PackQtyUnitId=this.dkbPdProd.packQtyUnitId
          this.editPdSpecDialogVisible = false
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    /*包装明细拆分弹窗--取消按钮*/
    cancelEditPdSpec() {
      this.editPdSpecDialogVisible = false
    },
    /* 编辑医保药品备注信息*/
    editDrugComment(row,drugType){
      this.editDrugCommentDialogVisible=true
      this.drugCommentForm.comment=row.comment
      this.drugCommentForm.drugId=row.id
      this.drugCommentForm.drugType=drugType
    },
    /* 保存医保药品备注信息*/
    submitEditDrugComment(){
      let form=this.drugCommentForm
      updateDrugComment(form.drugId,form.comment,form.drugType).then(res => {
        this.editDrugCommentDialogVisible=false
        this.msgSuccess()
        if(form.drugType==1){
          this.selectDictDrug('国家')
        }else{
          this.selectDictDrug('上海')
        }
      })
    },
    //校验包装材料描述是否重复
    validateWrapperDesc(rule, value, callback){
      let matList=this.dkbPdProd.pdProdMatList
      let nowIndex=this.editPdProdMatForm.index
      if(matList){
        let hasSame= matList.some((matItem,index)=>{
          return index!=nowIndex && matItem.wrapperDesc.trim()==value.trim()
        })
        if(hasSame){
          callback(new Error('包装材料描述已存在'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    },
    //根据国家医保补充上海医保 或 根据上海医保填充国家医保
    async autoFillNhsaOrSmpaDrugCode(drugCode, type) {
      let mappingData
      let sourceData
      if (type ==1) {
       await selectNhsaToSmpaDrugMapping(drugCode, "").then(res=>{
         mappingData=res.data && res.data.length>0?res.data[0].smpaDrugCode:""
        })
        sourceData=this.editPdProdMatForm.shMedicareNumList
      }else if (type ==2) {
        await  selectNhsaToSmpaDrugMapping("", drugCode).then(res=>{
          mappingData=res.data && res.data.length>0?res.data[0].nhsaDrugCode:""
        })
        sourceData=this.editPdProdMatForm.stateMedicareNumList
      }
      if(isEmpty(mappingData)){
        return
      }
      //检查重复
      let hasSame=sourceData.some(t=>{
        return t==mappingData
      })
      if(hasSame){
        return
      }
      //检查关联的国家医保代码或上海医保代码是否已被其他药品关联过
      let checkPass = await this.checkDrugCodeHasRelevance(type==1?2:1, mappingData,false)
      if(!checkPass){
        return
      }

      //没有重复的，添加
      let inserFlag=sourceData.some((item,index)=>{
        if(isEmpty(item)){
          sourceData[index]=mappingData
          return true
        }
      })
      if(!inserFlag){
        sourceData.push(mappingData)
      }
      this.$forceUpdate()

    },
    //检查医保代码是否被关联过
    async checkDrugCodeHasRelevance(type,drugCode,showMsg) {
      let pass=true
      //当前编辑的包装材料index
      let matIndex = this.editPdProdMatForm.index
      //1.本地校验，检查当前没有保存入库的数据
      let errorMsg;
      this.dkbPdProd.pdProdMatList.some((matItem, index) => {
        if (index != matIndex) {
          let codeList
          if (type == 1) {
            //国家医保
            codeList = matItem.stateMedicareNumList
          } else {
            codeList = matItem.shMedicareNumList
          }
          if (codeList.indexOf(drugCode) > -1) {
            errorMsg = "当前医保代码已被当前药品下包装材料为[" + matItem.wrapperDesc + "]的药品关联"
            if(showMsg){
              this.msgInfo(errorMsg)
            }
            pass=false
            return true;
          }
        }

      })
      if(pass==false){
        return pass
      }

      //2.调用接口远程校验
      let res = await checkDrugHasRelevance(type, drugCode).catch(()=>{
        pass=false
      })
      return pass
    }

  }
}
</script>

<style>
/*禁用编辑的输入框文本颜色*/
.el-input.is-disabled .el-input__inner {
  color: #606266 !important;
}
</style>


<style lang="scss" scoped>
/*分类标题*/
.title-bar {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}

.bottom-bar {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 60px;
  z-index: 200;
  font-size: 12px;
  line-height: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  padding-right: 260px;
}

.el-form-item {
  margin-bottom: 18px;
}

/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}

/*解决表格内部表单元素错位*/
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}
</style>
